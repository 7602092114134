const contact_api1 = {

    getDimensions:{//获取数据库维度
        url:'getDimension.do',
        method:'get',
    },
    searhDimension:{//搜索维度内数据
        url:'searchDimension.do',
        method:'get'
    },
    getDimensionChildren:{//获取维度子级
        url:'getDimensionList.do',
        method:'get'
    },
    getDimensionListSon:{
        url:'getDimensionListSon.do',
        method:'get'
    },
    syncData:{//查询数据
        url:'center.do',
        method:'post'
    },
    saveData:{
        url:'input/saveData.do',
        method:'post',
    },
    setConditionStyle:{//设置条件样式
        url:'concss.do',
        method:'get',
    },
    getVars:{//获取sheet标的行（变量）
        url:'getRowsMsg.do',
        method:'get'
    },
    getColumnsMsg:{//获取sheet标的列
        url:'getColumnsMsg.do',
        method:'get'
    },
    getCollectionFolder:{//获取我的收藏夹
        url:'collection/getAllFolder',
        method:'get'
    },
    createSeries:{//创建数据集
        url:'createReport.do',
        method:'get'
    },
    getSeriesList:{//获取序列集列表
        url:'getAllReportByCubeId.do',
        method:'get'
    },
    addSeries:{//添加序列
        url:'addReportForm.do',
        method:'get'
    },
    setGrowthRate:{
        url:'timeseries.do',
        method:'get'
    },
    getChartData:{
        url:'draw/charts.do',
        method:'post',
    },
    getPointChart:{
        url:'pointCharts.do',
        method:'get'
    },
    getChartsData:{
        url:'charts.do',
        method:'get'
    },
    getDimInfo:{
        url:'information/getDimInfo.do',
        method:'get'
    },
    userDefinedFn:{
        url:'highComputor.do',
        method:'post',
        noTrans:true
    },
    searchPage:{
        url:'searchPage.do',
        method:'get'
    },
    goCollection:{
        url:'collection/createFile',
        method:'post'
    },
    checkDownload:{
        url:'checkDownload.do',
        method:'get'
    },
    updateReportForm:{
        url:'updateReportForm.do',
        method:'get'
    },

    searchDimensionsMode:{
        url:'searchDimensionsMode.do',
        method:'get'
    },
    user_collection_get_all:{
        url:'collection/getAll',
        method:'get'
    },
    user_collection_get_allFolder:{
        url:'collection/getAllFolder',
        method:'get'
    },
    user_collection_move_file:{
        url:'collection/moveFile',
        method:'get'
    },
    user_createFolder:{
        url:'collection/createFolder',
        method:'post',
        noTrans:true
    },
    user_getFiles:{
        url:'collection/getAllCollectionFile',
        method:'get'
    },
    user_delFile:{
        url:'collection/deleteFile',
        method:'get'
    },
    user_editFile:{
        url:'collection/renameFile',
        method:'post',
        noTrans:true
    },
    user_getSeries:{
        url:'getAllReportCubeByName.do',
        method:'get'
    },
    user_getMydata:{
        url:'getMyData.do',
        method:'get'
    },
    user_del_history:{
        url:'user/deleteBrowsHistory.do',
        method:'get'
    },
    user_del_upload_indicator:{
        url:'deleteUploadIndicator.do',
        method:'get'
    },
    user_del_series_indicator:{
        url:'deleteIndicator.do',
        method:'get',
    },
    renameReport:{
        url:'renameReport.do',
        method:'post',
        noTrans:true
    },
    delReport:{
        url:'deleteReport.do',
        method:'get'
    },
    delUploadReport:{
        url:'deleteUploadCube.do',
        method:'get'
    },
    getReportIndicator:{
        url:'getReportIndicatorByReportId.do',
        method:'get'
    },
    getUploadIndicator:{
        url:'getCubeUploadIndicator.do',
        method:'get'
    },
    renameIndicator:{
        url:'renameIndicator.do',
        method:'post',
        noTrans:true
    },
    renameUploadIndicator:{
        url:'renameUploadIndicator.do',
        method:'post',
        noTrans:true
    },
    delSeriesIndicator:{
        url:'deleteDimData.do',
        method:'get'
    },
    delUploadIndicator:{
        url:'deleteDimData.do',
        method:'get'
    },
    getBrowseHistory:{
        url:'user/getBrowsHistory.do',
        method:'get'
    },
    userUpdate:{
        url:'user/update.do',
        method:'get'
    },



    cloud_get_all_report_cube:{
        url:'getAllReportByCubeId.do',
        method:'get'
    },

    cloud_get_my_data:{
        url:'getMyData.do',
        method:'get'
    },


    cloud_get_dimension:{
        url:'getDimension.do',
        method:'get'
    },
    cloud_sync_data:{
        url:'center.do',
        method:'post',
    },
    cloud_get_variables:{
        url:'variable.do',
        method:'get'
    },
    cloud_descstats:{
        url:'descstats.do',
        method:'post',
        noTrans:true
    },
    cloud_set_missvalue:{
        url:'missvalue.do',
        method:'post',
        noTrans:true
    },
    cloud_set_aggregation:{
        url:'aggregation.do',
        method:'get'
    },
    cloud_set_correlation:{
        url:'correlation.do',
        method:'get'
    },
    cloud_set_line_return:{
        url:'lr.do',
        method:'get'
    },
    cloud_set_curvefit:{
        url:'curvefit.do',
        method:'get'
    },
    cloud_set_leastSquares:{
        url:'leastSquares.do',
        method:'get'
    },
    cloud_set_autocorre:{
        url:'autocorre.do',
        method:'get'
    },
    cloud_set_hp:{
        url:'hp.do',
        method:'get'
    },
    cloud_set_expsmooth:{
        url:'expsmooth.do',
        method:'get'
    },
    cloud_set_arima:{
        url:'arima.do',
        method:'get'
    },
    cloud_set_unitRoot:{
        url:'unitRootTest',
        method:'get'
    },
    cloud_set_granger:{
        url:'granger',
        method:'get'
    },
    cloud_set_var:{
        url:'var',
        method:'get'
    },
    cloud_set_johansen:{
        url:'johansen',
        method:'get'
    },
    cloud_set_eg:{
        url:'eg',
        method:'get'
    },
    cloud_set_arch:{
        url:'arch',
        method:'get'
    },
    cloud_set_garch:{
        url:'garch',
        method:'get'
    },
    cloud_refresh:{
        url:'refresh.do',
        method:'get'
    },
    cloud_insert_mydata:{
        url:'insertMyData.do',
        method:'get'
    },
    cloud_get_indicator_info:{
        url:'getReportIndicatorByReportId.do',
        method:'get'
    },
    input_get_dimensions:{
        url:'/dimension/listDimension.do',
        method:'get'
    },
    inputs_add_dimension:{
        url:'dimension/addDimension.do',
        method:'post',
        noTrans:true
    },
    inputs_del_dimension:{
        url:'dimension/deleteDimension.do',
        method:'get',
    },
    input_edit_dimension:{
        url:'dimension/updateDimension.do',
        method:'post',
        noTrans:true
    },

    inputs_get_cubes:{
        url:'metaCube/listMetaCube.do',
        method:'get'
    },
    inputs_get_dimension_columns:{
        url:'getDimDataColumns.do',
        method:'get'
    },
    inputs_add_dim_data:{
        url:'addDimData.do',
        method:'post'
    },
    inputs_edit_dim_data:{
        url:'updateDimData.do',
        method:'post'
    },
    inputs_delete_dim_data:{
        url:'deleteDimData.do',
        method:'get'
    },
    inputs_add_cube:{
        url:'metaCube/addMetaCube.do',
        method:'post'
    },
    inputs_get_cube_info:{
        url:'metaCube/getMetaCube.do',
        method:'get'
    },
    inputs_edit_cube:{
        url:'metaCube/updateMetaCube.do',
        method:'post'
    },
    inputs_get_dim_data:{
        url:'getDimData.do',
        method:'get'
    },
    inputs_delete_cube:{
        url:'metaCube/deleteMetaCube.do',
        method:'get'
    },
    inputs_create_cube_folder:{
        url:'metaCube/addMetaCubeFolder.do',
        method:'post',
        noTrans:true
    },
    inputs_edit_cube_folder:{
        url:'metaCube/updateMetaCubeFolder.do',
        method:'post',
        noTrans:true
    },
    inputs_create_article_folder:{
        url:'dimFolder/addDimFolder.do',
        method:'post',
        noTrans:true
    },
    inputs_update_article_folder:{
        url:'dimFolder/updateDimFolder.do',
        method:'post',
        noTrans:true
    },
    // 原原文列表左侧
    // inputs_get_article_folder:{
    //     url:'dimFolder/listAllDimFolder.do',
    //     method:'get'
    // },
    //新的原文列表
    inputs_get_article_newfolder:{
        url:'dimStorage/listDimStorage.do',
        method:'get'
    },
    //原原文详情
    // inputs_get_article_detail:{
    //     url:'dimFile/getDimFile.do',
    //     method:'get'
    // },
    //新的原文详情
    inputs_get_article_newDetail:{
        url:'dimStorage/getDimStorage.do',
        method:'get'
    },
    inputs_delete_article_folder:{
        url:'dimFolder/deleteDimFolder.do',
        method:'get'
    },
    inputs_get_articles:{
        url:'dimFile/listDimFile.do',
        method:'get'
    },
    //原原文删除
    // inputs_delete_articles:{
    //     url:'dimFile/deleteDimFile.do',
    //     method:'get'
    // },
    //新的原文删除
    inputs_delete_articles:{
        url:'dimStorage/deleteDimStorage.do',
        method:'get'
    },
    inputs_add_reback:{
        url:'feedBack/addFeedBack.do',
        method:'post',
        noTrans:true
    },
    inputs_update_reback:{
        url:'feedBack/updateFeedBack.do',
        method:'post',
        noTrans:true
    },
    inputs_replay_reback:{
        url:'feedBack/dealFeedBack.do',
        method:'post',
        noTrans:true
    },
    inputs_get_rebacks:{
        url:'feedBack/listFeedBack.do',
        method:'get'
    },
    inputs_delete_rebacks:{
        url:'feedBack/deleteFeedBack.do',
        method:'get'
    },
    inputs_add_new_year:{
        url:'input/refreshYear.do',
        method:'get'
    },
    user_info_center:{
        url:'message/getUserMessage',//消息中心
        method:'get' 
    },
    inputs_get_leaderList:{
        url:'job/teamHeadJob',//任务分配列表
        method:'get'
    },
    inputs_get_deleteLeaderListItem:{
        url:'job/deleteJob',//任务删除
        method:'get'
    },
    inputs_get_detail:{
        url:'job/getJobById',//任务详情
        method:'get'
    },
    inputs_get_groupList:{
        url:'team/getTeamList',//选择小组
        method:'get'
    },
    inputs_get_addJob:{
        url:'job/addJob',//分配工作提交
        method:'post',
        noTrans:true
    },
    inputs_get_updateJob:{
        url:'job/updateJob',//分配工作修改提交
        method:'post',
        noTrans:true
    },
    inputs_get_onlineJob:{
        url:'job/onlineJob',//领导上线
        method:'get'
    },
    inputs_get_getTeamUser:{
        url:'team/getTeamUser',//小组长获取组员
        method:'get'
    },
    inputs_get_allTaskList:{
        url:'job/listJob',//小组长和成员所有任务
        method:'get'
    },
    inputs_get_submitJob:{
        url:'/job/submitJob',//小组长提交任务
        method:'get'
    },
    inputs_get_submitJob2:{
        url:'/job/checkJob',//小组长提交任务
        method:'get'
    },
    inputs_get_myJob:{
        url:'job/myJob',//组员我的任务
        method:'get'
    },
    home_index_inquireList:{
        url:'index/indexIndicator',//首页数据查询
        method:'get'
    },
    home_index_LineIndicator:{
        url:'index/indexLineIndicator',//首页财政运行指标
        method:'get'
    },
    home_index_getIndicatorLine:{
        url:'index/getIndicatorLine',//首页财政运行图表
        method:'get'
    },
    getAllDimCodes:{
        url:'getDimensionAll.do',
        method:'get'
    },
    listMetaCubeTree:{
        url:'metaCube/listMetaCubeTree.do',
        method:'get'
    },
    getSource:{
        url:'dicSource/listDicSource.do',
        method:'get'
    },
    addSource:{
        url:'dicSource/addDicSource.do',
        method:'post',
        noTrans:true
    },
    editSource:{
        url:'dicSource/updateDicSource.do',
        method:'post',
        noTrans:true
    },
    getNameSpaces:{
        url:'dicCubeSchema/listDicCubeSchema.do',
        method:'get'
    },
    getTags:{
        url:'dimTag/listDimTag.do',
        method:'get'
    },
    addTag:{
        url:'dimTag/addDimTag.do',
        method:'get'
    },
    editTag:{
        url:'dimTag/updateDimTag.do',
        method:'get'
    },
    delTag:{
        url:'dimTag/deleteDimTag.do',
        method:'get'
    }
}

//用户相关
const contact_api3={
    home_index_map:{
        url:'naviga/navigaList',//首页地区
        method:'get'
    },
    home_index_dataService:{
        url:'/dataService/list',//首页地区
        method:'get'
    },
    getSign:{//获取发送验证码的签名
        url:'verify/getSign',
        method:'get'
    },
    sendCode:{//发送验证码
        url:'verify/sendCode',
        method:'get'
    },
    login:{//登录
        url:'login/login',
        method:'post',
        noTrans:true
    },
    isNeedVerifyCode:{
        url:'login/needVerifyCode',
        method:'get'
    },
    register:{//注册
        url:'login/registerUser',
        method:'post',
        noTrans:true
    },
    //原重置密码
    // resetPwd:{
    //     url:'ptUser/changePwd.do',
    //     method:'post',
    //     noTrans:true
    // },
    //新重置密码
    resetPwd:{
        url:'login/forgetPwd.do',
        method:'post',
        noTrans:true
    },
    getUserInfo:{//获取用户信息
        url:'login/userInfo',
        method:'get'
    },
    infoCenterChanggePwdByPwd:{//个人中心原密码重置密码
        url:'login/changgePwdByPwd.do',
        method:'post',
        noTrans:true
    },
    changeNewPhone:{//个人中心更换手机号
        url:'login/changePhone.do',
        method:'post',
        noTrans:true
    }
}
const CONTACT_API = [contact_api1,contact_api3];
export default CONTACT_API;