import axios from 'axios'
import service from './contactApi';
import config from './configs';
import { messageTips,getCookie } from './tools';
// service 循环遍历输出不同的请求方法

const Http = {}; // 包裹请求方法的容器

// 请求格式/参数的统一
service.forEach((item,key)=>{
    let instance = axios.create({
        baseURL:config.baseUrl[key],
        timeout:600000
    });
    for(let key in item){
        let api = item[key]; // url method
        // async 作用：避免进入回调地狱
        Http[key] = async function(
            params, // 请求参数 get：url，put，post，patch（data），delete：url
            isFormData=false,// 标识是否是form-data请求
            config={} // 配置参数
        ){
            let newParams = {}
            let sid = getCookie('bd_sid');
            //  content-type是否是form-data的判断
            if(params && isFormData){
                newParams = new FormData()
                for(let i in params){
                    newParams.append(i,params[i])
                }
            }else{
                newParams = params
            }
            if(newParams) {
                newParams.sid = sid;
            } else {
                newParams = {
                    sid:sid
                }
            }
            // 不同请求的判断
            let response = {}; // 请求的返回值
            if(api.method === 'post'){
                try{                
                    if(api.noTrans) {
                        var postParams = new URLSearchParams();
                        for(let i in newParams) {
                            postParams.append(i,newParams[i]);
                        }
                        response =  await instance[api.method](api.url,postParams,config)
                    } else {
                        if(api.url.indexOf('sid')===-1) {
                            api.url+='?sid='+sid;
                        }
                        response =  await instance[api.method](api.url,newParams,config)
                    }
                }catch(err){
                    response = err
                }
            }else if(api.method === 'delete'|| api.method === 'get'){
                config.params = newParams
                try{
                    response =  await instance[api.method](api.url,config)
                }catch(err){
                    response = err
                }
            }
            return response; // 返回响应值
        }
    }

    // 拦截器的添加
    // 请求拦截器
    instance.interceptors.request.use(config=>{
        // 发起请求前做些什么
        
        return config
    },()=>{
        // 请求错误
        
    })
    // 响应拦截器
    instance.interceptors.response.use(res=>{
        // 请求成功
        if(res.data.code===200) {
            return res.data.data;
        } else {
            return res.data;
        }        
    },(error)=>{
        var tips;
        if(error&&error.response) {
            switch(error.response.status){
                case 400:
                        tips = '错误请求-'+error.response.status;
                    break;
                case 401:
                        tips = '请先登录';
                    break;
                case 403:
                        tips = '拒绝访问-'+error.response.status;
                    break;
                case 404:
                        tips = '错误请求，未找到该资源-'+error.response.status;
                    break;
                case 405:
                        tips = '请求方法未允许-'+error.response.status;
                    break;
                case 408:
                        tips = '请求超时-'+error.response.status;
                    break;
                case 500:
                        if(error.response.data.message) {
                            tips = error.response.data.message;
                        } else {
                            tips = '服务器端出错-'+error.response.status;
                        }                    
                    break;
                case 501:
                        tips = '网络未实现-'+error.response.status;
                    break;
                case 502:
                        tips = '网络错误-'+error.response.status;
                    break;
                case 503:
                        tips = '服务不可用-'+error.response.status;
                    break;
                case 504:
                        tips = '网络超时-'+error.response.status;
                    break;
                case 505:
                        tips = 'http版本不支持该请求-'+error.response.status;
                    break;
                default:
                    tips = `连接错误${error.response.status}`;
                    break;
            }
        } else{
            tips = '网络出现问题，请稍候重试';
        }
        messageTips(tips,1);
    })
});




export default Http;