import Vue from 'vue';
import Router from 'vue-router';
import {delSessionItem, getCookie} from '../until/tools';
Vue.use(Router);
const originalPush = Router.prototype.push 
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
function checkLogin(next) {
	let sid = getCookie('bd_sid');
	if(sid) {
		next();
	} else {
		delSessionItem('dims');
		next('/login');
	}
}
export default new Router({
	mode:'hash',
	routes: [
		// {
		// 	path:'/home',
		// 	component:()=>import(/* webpackChunkName: "home" */ '../pages/home/index.vue'),
		// 	meta:{title:'首页'},
		// },	
		// {
		// 	path:'/account',
		// 	component:()=>import(/* webpackChunkName: "login" */ '../pages/account/Index.vue'),
		// 	meta:{title:'账号'},
		// 	children:[
		// 		{
		// 			path:'/account',
		// 			redirect:'/login'
		// 		},
		// 		{
		// 			path:'/login',
		// 			component:()=>import(/* webpackChunkName: "login" */ '../pages/account/Login.vue'),
		// 			meta:{title:'登录'}
		// 		},
		// 		{
		// 			path:'/register',
		// 			component:()=>import(/* webpackChunkName: "register" */ '../pages/account/Register.vue'),
		// 			meta:{title:'注册'},
		// 		},
		// 		{
		// 			path:'/resetPwd',
		// 			component:()=>import(/* webpackChunkName: "resetPwd" */ '../pages/account/ResetPwd.vue'),
		// 			meta:{title:'重置密码'},
		// 		},
		// 	],
		// },
		{
			path:'/account',
			component:()=>import(/* webpackChunkName: "login" */ '../pages/account/new_index.vue'),
			meta:{title:'账号'},
			children:[
				{
					path:'/account',
					redirect:'/login'
				},
				{
					path:'/login',
					component:()=>import(/* webpackChunkName: "login" */ '../pages/account/new_login.vue'),
					meta:{title:'登录'}
				},
				{
					path:'/register',
					component:()=>import(/* webpackChunkName: "register" */ '../pages/account/new_register.vue'),
					meta:{title:'注册'},
				},
				{
					path:'/resetpwd',
					component:()=>import(/* webpackChunkName: "resetPwd" */ '../pages/account/new_resetpwd.vue'),
					meta:{title:'重置密码'},
				},
			],
		}, 
		{
			path:'/inputs',
			component:()=>import(/* webpackChunkName: "platform" */ '../pages/inputs/Platform.vue'),
			meta:{title:'数据平台'},
			beforeEnter:(to,from,next)=>{
				let sid = getCookie('bd_sid');
				let canInput = getCookie('canInput');
				if(sid) {
					if(canInput) {
						next();
					} else {
						next('/datas');
					}					
				} else {
					delSessionItem('dims');
					next('/login');
				}
			},
			children:[
				{
					path:'/inputs_cube',
					component:()=>import(/* webpackChunkName: "cube" */ '../pages/inputs/Cube.vue'),
					meta:{title:'数据库维护'},
				},
				{
					path:'/inputs_data',
					component:()=>import(/* webpackChunkName: "data" */ '../pages/inputs/Data.vue'),
					meta:{title:'数据维护'},
				},
				{
					path:'/inputs_dictionary',
					component:()=>import(/* webpackChunkName: "dictionary" */ '../pages/inputs/Dictionary.vue'),
					meta:{title:'数据字典'},
				},
				{
					path:'/inputs_source',
					component:()=>import(/* webpackChunkName: "source" */ '../pages/inputs/Source.vue'),
					meta:{title:'来源字典'},
				},
				{
					path:'/inputs_tags',
					component:()=>import(/* webpackChunkName: "tags" */ '../pages/inputs/Tags.vue'),
					meta:{title:'标签字典'},
				},
				{
					path:'/inputs_article',
					component:()=>import(/* webpackChunkName: "article" */ '../pages/inputs/Articles.vue'),
					meta:{title:'原文'},
				},
				{
					path:'/inputs_reback',
					component:()=>import(/* webpackChunkName: "reback" */ '../pages/inputs/Reback.vue'),
					meta:{title:'反馈'},
				},
				{
					path:'/inputs_allocation',
					component:()=>import(/* webpackChunkName: "allocation" */ '../pages/inputs/Allocation.vue'),
					meta:{title:'任务分配'},
				},
				{
					path:'/inputs_teamAllocation',
					component:()=>import(/* webpackChunkName: "teamAllocation" */ '../pages/inputs/teamAllocation.vue'),
					meta:{title:'小组分配'},
				}, 
				{
					path:'/inputs_itemAllocation',
					component:()=>import(/* webpackChunkName: "itemAllocation" */ '../pages/inputs/itemAllocation.vue'),
					meta:{title:'成员分配'},
				},
				{
					path:'/inputs_addTask',
					component:()=>import(/* webpackChunkName: "addTask" */ '../pages/inputs/addTask.vue'),
					meta:{title:'新增任务'},
				},
				{
					path:'/inputs_teamAddTask',
					component:()=>import(/* webpackChunkName: "teamAddTask" */ '../pages/inputs/teamAddTask.vue'),
					meta:{title:'分配任务'},
				},
				{
					path:'/inputs_infoCenter',
					component:()=>import(/* webpackChunkName: "infoCenter" */ '../pages/inputs/infoCenter.vue'),
					meta:{title:'消息中心'},
				},
				{
					path:'/inputs',
					redirect:'/inputs_cube'
				}
			]
		},
		{
			path:'/user',
			component:()=>import(/* webpackChunkName: "user" */ '../pages/user/Index.vue'),
			meta:{title:'个人中心'},
			beforeEnter:(to,from,next)=>{
				checkLogin(next);
			},
			children:[
				{
					path:'/user_info',
					component:()=>import(/* webpackChunkName: "user" */ '../pages/user/Info.vue'),
				},
				{
					path:'/user_collections',
					component:()=>import(/* webpackChunkName: "collections" */ '../pages/user/Collections.vue'),
				},
				{
					path:'/user_series',
					component:()=>import(/* webpackChunkName: "series" */ '../pages/user/Series.vue'),
				},
				{
					path:'/user_series_indicator',
					component:()=>import(/* webpackChunkName: "series" */ '../pages/user/SeriesIndicator.vue'),
				},
				{
					path:'/user_history',
					component:()=>import(/* webpackChunkName: "history" */ '../pages/user/History.vue'),
				},
				{
					path:'/',
					redirect:'/user_info',
				}
			]
		},
		{
			path:'/datas',
			component:()=>import(/* webpackChunkName: "datas" */ '../pages/datas/Index.vue'),
			meta:{title:'数据平台'},
			beforeEnter:(to,from,next)=>{
				checkLogin(next);
			},
			children:[
				{
					path:'/datas_data',
					component:()=>import(/* webpackChunkName: "data" */ '../pages/datas/Data.vue'),
					meta:{title:'数据查询'},
				},
				{
					path:'/datas_search',
					component:()=>import(/* webpackChunkName: "data" */ '../pages/datas/Search.vue'),
					meta:{title:'数据跨库搜索'},
				},
				{
					path:'/datas_articles',
					component:()=>import(/* webpackChunkName: "articles" */ '../pages/datas/Articles.vue'),
					meta:{title:'原文查看'},
				},
				{
					path:'/datas_reback',
					component:()=>import(/* webpackChunkName: "reback" */ '../pages/datas/Reback.vue'),
					meta:{title:'意见反馈'},
				},
				{
					path:'/datas',
					redirect:'/datas_data'
				}
			]
		},
		{
			path:'/cloud',
			component:()=>import(/* webpackChunkName: "cloud" */ '../pages/cloud/cloud.vue'),
			meta:{title:'云分析'},
			beforeEnter:(to,from,next)=>{
				checkLogin(next);
			},
			children:[
				{
					path:'/cloud_index',
					component:()=>import(/* webpackChunkName: "cloud_index" */ '../pages/cloud/index.vue'),
					meta:{title:'云分析平台'},
				},
				{
					path:'/cloud_result',
					component:()=>import(/* webpackChunkName: "cloud_index" */ '../pages/cloud/result.vue'),
					meta:{title:'云分析结果'},
				},
				{
					path:'/',
					redirect:'/cloud_index'
				}
			]
		},
		{
			path:'/analysis',
			component:()=>import(/* webpackChunkName: "analysis" */ '../pages/analysis/index.vue'),
			meta:{title:'分析结果'},
			children:[
				{
					path:'/analysis_bivar',
					component:()=>import(/* webpackChunkName: "analysis_bivar" */ '../pages/analysis/bivar.vue'),
					meta:{title:'双变量分析结果'}
				},
				{
					path:'/analysis_partial',
					component:()=>import(/* webpackChunkName: "analysis_partial" */ '../pages/analysis/partial.vue'),
					meta:{title:'偏相关分析结果'}
				},
				{
					path:'/analysis_lr',
					component:()=>import(/* webpackChunkName: "analysis_lr" */ '../pages/analysis/lr.vue'),
					meta:{title:'线性回归分析结果'}
				},
				{
					path:'/analysis_curvefit',
					component:()=>import(/* webpackChunkName: "analysis_curvefit" */ '../pages/analysis/curvefit.vue'),
					meta:{title:'曲线估计分析结果'}
				},
				{
					path:'/analysis_leastsquares',
					component:()=>import(/* webpackChunkName: "analysis_leastsquares" */ '../pages/analysis/leastsquares.vue'),
					meta:{title:'二阶段最小二乘分析结果'}
				},
				{
					path:'/analysis_autocorre',
					component:()=>import(/* webpackChunkName: "analysis_autocorre" */ '../pages/analysis/autocorre.vue'),
					meta:{title:'自相关乘分析结果'}
				},
				{
					path:'/analysis_hp',
					component:()=>import(/* webpackChunkName: "analysis_hp" */ '../pages/analysis/hp.vue'),
					meta:{title:'hp滤波分析结果'}
				},
				{
					path:'/analysis_arima',
					component:()=>import(/* webpackChunkName: "analysis_arima" */ '../pages/analysis/arima.vue'),
					meta:{title:'ARIMA分析结果'}
				},
				{
					path:'/analysis_expsmooth',
					component:()=>import(/* webpackChunkName: "analysis_expsmooth" */ '../pages/analysis/expsmooth.vue'),
					meta:{title:'指数平滑分析结果'}
				},
				{
					path:'/analysis_unitCheck',
					component:()=>import(/* webpackChunkName: "analysis_unitCheck" */ '../pages/analysis/unitcheck.vue'),
					meta:{title:'单位根检验结果'}
				},
				{
					path:'/analysis_grangerCheck',
					component:()=>import(/* webpackChunkName: "analysis_grangerCheck" */ '../pages/analysis/grangerCheck.vue'),
					meta:{title:'格兰杰因果检验'}
				},
				{
					path:'/analysis_varModel',
					component:()=>import(/* webpackChunkName: "analysis_varModel" */ '../pages/analysis/varModel.vue'),
					meta:{title:'var模型结果'}
				},
				{
					path:'/analysis_johansen',
					component:()=>import(/* webpackChunkName: "analysis_johansen" */ '../pages/analysis/johansen.vue'),
					meta:{title:'johansen协整检测'}
				},
				{
					path:'/analysis_engleGranger',
					component:()=>import(/* webpackChunkName: "analysis_engleGranger" */ '../pages/analysis/engleGranger.vue'),
					meta:{title:'engle-granger协整分析'}
				},
				{
					path:'/analysis_arch',
					component:()=>import(/* webpackChunkName: "analysis_arch" */ '../pages/analysis/arch.vue'),
					meta:{title:'ARCH效应检验'}
				},
				{
					path:'/analysis_garch',
					component:()=>import(/* webpackChunkName: "analysis_garch" */ '../pages/analysis/garch.vue'),
					meta:{title:'GARCH模型'}
				},
			]
		},
		{
			path:'/',
			redirect:'/login',
		},
	]
});