import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
const store = new Vuex.Store({
    state: {        
        userInfo:{},
        cubes:[],
    },
    getters:{        
        getUserInfo(state) {
            return state.userInfo;
        },
        getCubes(state) {
            return state.cubes;
        }
    },
    mutations:{		
        setUserInfo(state,data) {            
            state.userInfo = data;
        },
        setCubes(state,data) {
            state.cubes = data;
        }
    },
    
});
 
export default store;