import Vue from 'vue'
import App from './App.vue'
import store from './store';
import router from './router/router';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import Http from './until/http';
import './scss/base.scss';
import './scss/iconfont.css';
import vcolorpicker from 'vcolorpicker'
import vuescroll from "vuescroll";//引入vuescroll
import "vuescroll/dist/vuescroll.css";//引入vuescroll样式
import VueAMap from 'vue-amap';
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
	key: '47579c505edaa2f1f85c4472c2eabfde',
	plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType','AMap.Geolocation','Geolocation','AMap.DistrictSearch'],
	uiVersion: '1.0',
	v: '1.4.4'
});
Vue.use(vuescroll);//使用
Vue.use(vcolorpicker)
Vue.use(Antd);
Vue.config.productionTip = false
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
Vue.prototype.$http=Http;
router.afterEach(() => {
	window.scrollTo(0,0);
});
new Vue({
	router,
	store,
	beforeCreate(){
		Vue.prototype.$bus = this
	},
	render: h => h(App),
}).$mount('#app')
