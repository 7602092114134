let basePath = '/eps'
const config = {
    baseUrl:[
        '/eps/',
        '/user/',
    ],
    delTips:{
        title:'你确定要删除吗？',
        content:'删除后不可恢复，你确定要删除吗？'
    },
    imgCode:'/user/verify/imageCode',
    cloudUploadData:basePath+'/uploadMyData.do', 
    inputsImportFile:basePath+'/input/uploadInputData',
    downloadTable:basePath+'/download.do',
    downloadData:basePath+'/downloadData.do',
    inputs_export_dim:basePath+'/downloadDimData.do',
    inputs_upload_dim_data:basePath+'/upload/uploadDimData',
    // addDimFile:basePath+'/dimFile/addDimFile.do',
    addDimFile:basePath+'/dimStorage/addDimStorage.do',
    // updateDimFile:basePath+'/dimFile/updateDimFile.do',
    updateDimFile:basePath+'/dimStorage/updateDimStorage.do',
    downloadArticle:basePath+'/dimFile/downloadDimFile.do',
    analysisResultDownload:basePath+'/downloadCorr.do',
    originalDownload:basePath+'/dimStorage/downloadDimStorage.do',
    gaodeMapUrl:'https://webapi.amap.com/maps?v=1.4.14&key=47579c505edaa2f1f85c4472c2eabfde&plugin=Map3D,AMap.DistrictLayer,AMap.Scale,AMap.ToolBar,AMap.Geocoder,DistrictSearch&callback=onBMapCallback',
    vueScrollSetting:{
        vuescroll: {},
        scrollPanel: {},
        rail: {
            keepShow: true
        },
        bar: {
            hoverStyle: true,
            onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
            background: "#aaa",//滚动条颜色
            opacity: 0.5,//滚动条透明度
            'overflow-x': 'hidden'
        }
    },
    chartColors:['#409eff', '#92d966', '#f890f7', '#b4b604','#2c5e92','#b706d7','#05f1fa','#ef9f14','#ff5400','#087634'],
}
export default config;

