import { message } from 'ant-design-vue';
export function messageTips(tips, type, time) {
    message.config({ top: '48%', });
    switch (type) {
        case 1:
            message.error({
                duration: time || 2,
                maxCount: 1,
                key: type,
                icon: (h) => {
                    h();
                    return <a-icon type="close-circle" color="#eb5a33" />;
                },
                content: tips,
                onClose: () => {
                    message.destroy();
                }
            });
            break;
        case 2:
            message.warning({
                duration: time || 2,
                maxCount: 1,
                key: type,
                icon: (h) => {
                    h();
                    return <a-icon type="exclamation-circle" color="#eb5a33" />;
                },
                content: tips,
                onClose: () => {
                    message.destroy();
                }
            });
            break;
        case 3:
            message.success({
                duration: time || 2,
                maxCount: 1,
                key: type,
                icon: (h) => {
                    h();
                    return <a-icon type="check-circle" color="#52c41a" />;
                },
                content: tips,
                onClose: () => {
                    message.destroy();
                }
            });
            break;
        default: break;
    }
}

export function checkIsInt(nubmer) {
    var re = parseInt(nubmer).toString() === 'NaN';
    return !re;
}


/**
 * 手机号码正则验证
 * @param value
 */
export function isPhone(value) {
    // var reg = /^1[0-9]{10}$/;
    var reg = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/;
    if (!reg.test(value)) {
        return false;
    } else {
        return true;
    }
}

/**
 * 邮箱正则验证
 * @param value
 */
export function isEmail(value) {
    var reg = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
    if (!reg.test(value)) {
        return false;
    } else {
        return true;
    }
}

//获取cookie
export function getCookie(sname) {
    var acookie = document.cookie.split('; ');
    for (var i = 0; i < acookie.length; i++) {
        var arr = acookie[i].split('=');
        if (sname === arr[0]) {
            if (arr.length > 1)
                return unescape(arr[1]);
            else
                return '';
        }
    }
    return '';
}
//设置cookie
export function setCookie(name, value, option, timeType) {
    var seconds;
    if (timeType) {
        seconds = option.time || 0;
    } else {
        seconds = option.time * 24 * 60 * 60 || 0;
    }

    var expires = '';
    if (seconds !== 0) {      //设置cookie生存时间
        var date = new Date();
        date.setTime(date.getTime() + (seconds * 1000));
        expires = '; expires=' + date.toGMTString();
    }
    var path = option.path || '/';
    document.cookie = name + '=' + escape(value) + expires + '; path=' + path;
}
//删除cookie
export function delCookie(name) {
    setCookie(name, '', { time: -1 });
}

export function setSessionItem(key, value) {
    if (typeof (value) !== 'string') {
        value = JSON.stringify(value);
    }
    return window.sessionStorage.setItem(key, value);
}
export function getSessionItem(key) {
    var string = window.sessionStorage.getItem(key);
    try {
        var obj = JSON.parse(string);
        if (typeof obj === 'object' && obj) {
            return obj;
        } else {
            if (string === 'null') {
                return null;
            } else {
                return string;
            }
        }
    } catch (e) {
        if (string === 'null') {
            return null;
        } else {
            return string;
        }
    }
}
export function delSessionItem(name) {
    window.sessionStorage.removeItem(name);
}

export function thousandSplit(num) {
    return ('' + num).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')

}

export function objToUrl(obj) {
    let arr = [];
    for (let i in obj) {
        if (obj[i]) {
            arr.push(encodeURIComponent(i) + '=' + encodeURIComponent(obj[i]));
        }
    }
    return arr.join('&');
}

export function savePicture(Url) {
    var blob = new Blob([''], { type: 'application/octet-stream' });
    var url = URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = Url;
    a.download = Url.replace(/(.*\/)*([^.]+.*)/ig, "$2").split("?")[0];
    var e = document.createEvent('MouseEvents');
    e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    a.dispatchEvent(e);
    URL.revokeObjectURL(url);
}

export function trim(str) { //删除左右两端的空格
    return str.replace(/(^\s*)|(\s*$)/g, "");
}

//设置localStorage
export function setLocalItem(key, value) {
    if (typeof (value) !== 'string') {
        value = JSON.stringify(value);
    }
    return window.localStorage.setItem(key, value);
}


export function getLocalItem(key) {
    var string = window.localStorage.getItem(key);
    try {
        var obj = JSON.parse(string);
        if (typeof obj === 'object' && obj) {
            return obj;
        } else {
            if (string === 'null') {
                return null;
            } else {
                return string;
            }
        }
    } catch (e) {
        if (string === 'null') {
            return null;
        } else {
            return string;
        }
    }
}


export function delLocalItem(name) {
    window.localStorage.removeItem(name);
}

// 使用 Buffer 对象将字符串转换为 Base64 字符串
export function stringToBase64(str) {
    return Buffer.from(str).toString('base64');
  }